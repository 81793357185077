import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import AddIcon from '@mui/icons-material/Add';
// Images
import logo from "../../images/logo.png";
import logoWhite from "../../images/logo-white.png";
import { Button } from "antd";
import WaitlistModal from "../../Modal/WaitlistModal";
// import { PlusOne } from "@material-ui/icons";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchFormOpen, setIsSearchBtn] = useState(false);
  const quikSearchBtn = () => setIsSearchBtn(!isSearchFormOpen);
  const quikSearchClose = () => setIsSearchBtn(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [waitlistModalVisible, setWaitlistModalVisible] = useState(false);
  const showWaitlistModal = () => {
    setWaitlistModalVisible(true);
  };
  const hideWaitlistModal = () => {
    setWaitlistModalVisible(false);
  };

  const handleJoinWaitlist = (email) => {
    // Handle the logic for joining the waitlist
    console.log('Joined waitlist with email:', email);
    hideWaitlistModal();
  };
  const toggleSubmenu = (item) => {
    setActiveItem(item === activeItem ? null : item);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleMenuLinkClick = () => {
    if (window.innerWidth <= 991) {
      setIsMenuOpen(false);
    }
  };

  const handleContactBtnClick = () => {
    setIsMenuOpen(false);
    // Implement the logic to toggle the contact sidebar here.
  };

  const handleMenuCloseClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth >= 768);
    };

    // Check the screen size on initial render and whenever the window is resized
    handleResize();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menuItems = [
    {
      id: "Features",
      name: "Features",
      linkName: "Features",
    },
    // {
    //   id: "pages",
    //   name: "Pages",
    //   linkName: "#",

    //   subItems: [
    //     {
    //       id: "aboutus",
    //       displayName: "About Us",
    //       linkName: "about-us",
    //     },
    //     {
    //       id: "team",
    //       displayName: "Our Team",
    //       linkName: "team",
    //     },
    //     {
    //       id: "faq",
    //       displayName: "FAQ's",
    //       linkName: "faq",
    //     },
    //     {
    //       id: "booking",
    //       displayName: "Booking",
    //       linkName: "booking",
    //     },
    //     {
    //       id: "error",
    //       displayName: "Error 404",
    //       linkName: "error-404",
    //     },
    //     {
    //       id: "formLogin",
    //       displayName: "Login / Register",
    //       linkName: "form-login",
    //     },
    //   ],
    // },
    {
      id: "How It Works",
      name: "How It Works",
      linkName: "HowWorks",

      // subItems: [
      //   {
      //     id: "services",
      //     displayName: "Service",
      //     linkName: "services",
      //   },
      //   {
      //     id: "serviceDetail",
      //     displayName: "Service Detail",
      //     linkName: "service-detail",
      //   },
      // ],
    },
    // {
    //   id: "blog",
    //   name: "Blog",
    //   linkName: "#",

    //   subItems: [
    //     {
    //       id: "blogGrid",
    //       displayName: "Blogs",
    //       linkName: "blog-grid",
    //     },
    //     {
    //       id: "blogDetails",
    //       displayName: "Blog Details",
    //       linkName: "blog-details",
    //     },
    //   ],
    // },
    // {
    //   id: "calculator",
    //   name: "Calculator",
    //   linkName: "Calculator",
    // },
    {
      id: "FAQs",
      name: "FAQs",
      linkName: "FAQs",
    },
    {
      id: "Contact Us",
      name: "Contact Us",
      linkName: "ContactUs",
    },


  ];

  return (
    <header className="header header-transparent rs-nav">
      <Sticky enabled={true} className="sticky-header navbar-expand-lg">
        <div className="menu-bar clearfix">
          <div className="container-fluid clearfix">
            <div className="menu-logo logo-dark">
              <Link to="/">
                <img src={require("../../images/logo.png")} alt="" />
              </Link>
            </div>

            <button
              className={`navbar-toggler collapsed menuicon justify-content-end ${isMenuOpen ? "open" : ""
                }`}
              type="button"
              onClick={toggleMenu}
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>



            <div
              className={`menu-links navbar-collapse collapse justify-content-end ${isMenuOpen ? "show" : ""
                }`}
              id="menuDropdown"
            >
              <div className="menu-logo">
                <Link to="/">
                  <img src={logoWhite} alt="" />
                </Link>
              </div>

              <ul className="nav navbar-nav">
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    className={`${activeItem === item.id ? "open" : ""}`}
                    onClick={() => toggleSubmenu(item.id)}
                  >
                    {item.subItems ? (
                      <Link to="#">
                        {item.name}
                        <i className={`fas fa-plus`}></i>
                      </Link>
                    ) : (
                      <a
                        href={`#${item.linkName}`}
                        onClick={handleMenuLinkClick}
                      >
                        {item.name}
                      </a>
                    )}
                    {(isMobileView || activeItem === item.id) &&
                      item.subItems && (
                        <ul className="sub-menu">
                          {item.subItems.map((subItem, index) => (
                            <li key={subItem.id}>
                              <Link
                                to={`/${subItem.linkName}`}
                                onClick={handleMenuLinkClick}
                              >
                                <span>{subItem.displayName}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
                <li>
                  <Button className="btn-primary text-white rounded-2 "
                    onClick={showWaitlistModal}
                  >
                    <AddIcon />       Join the Waitlist

                  </Button>
                </li>
              </ul>

              {/* <ul className="social-media">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/"
                    className="btn btn-primary"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/"
                    className="btn btn-primary"
                  >
                    <i className="fab fa-google"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/"
                    className="btn btn-primary"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/"
                    className="btn btn-primary"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
              </ul> */}

              <div className="menu-close" onClick={handleMenuCloseClick}>
                <i className="ti-close"></i>
              </div>
            </div>
          </div>
        </div>
      </Sticky>

      <div className={`nav-search-bar ${isSearchFormOpen ? "show" : ""}`}>
        <form>
          <input
            type="text"
            className="form-control"
            placeholder="Type to search"
          />
          <span>
            <i className="ti-search"></i>
          </span>
        </form>
        <span id="searchRemove" onClick={quikSearchClose}>
          <i className="ti-close"></i>
        </span>
      </div>
      <WaitlistModal
        visible={waitlistModalVisible}
        onCancel={hideWaitlistModal}
        onJoinWaitlist={handleJoinWaitlist}
      />
    </header>
  );
};

export default Header;
