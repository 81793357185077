
import firebase from "firebase";
import "firebase/storage";

const firebaseDevelopment = {
  apiKey: "AIzaSyD1KrPkkXpkgg9oqZmpyezBZVP9GzbXR4U",
  authDomain: "faith-connect-95c9b.firebaseapp.com",
  projectId: "faith-connect-95c9b",
  storageBucket: "faith-connect-95c9b.appspot.com",
  messagingSenderId: "1083640284799",
  appId: "1:1083640284799:web:0edfeb7dd352cbbe5c614a",
  measurementId: "G-C4QQFYBLE8"
};

// Initialize Firebase
// firebase.initializeApp(
//   process.env.REACT_APP_ENVIRONMENT === 'development'
//     ? firebaseDevelopment
//     : firebaseProduction,
// )
firebase.initializeApp(firebaseDevelopment);

firebase.analytics();

const storage = firebase.storage();

export { firebase, storage };
