import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Col, Row, message } from 'antd';


// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import pic1 from "../../images/about/pic4.png";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
import animateRotate from "../../images/shap/plus-blue.png";
import emailjs from "emailjs-com";

// import { Button, Checkbox } from "antd";
import { Checkbox } from "antd";
const emailServiceConfig = {
  serviceID: "service_1i2ryzq",
  templateID: "template_xosro0b",
  userID: "OE__t6fGuIYBEVsbx",
};


class ContactUs extends Component {
  handleFormSubmit = async (values) => {
    // console.log(values)
    try {
      // Prepare the email message
      const message = `
        Practice: ${values.practiceName}
        Name: ${values.name}
        Title: ${values.title}
        Email: ${values.email}
        Phone: ${values.phone}
        Selected Services: ${values.selectedServices.join(", ")}
      `;

      // Send the email using EmailJS
      await emailjs.send(
        emailServiceConfig.serviceID,
        emailServiceConfig.templateID,
        {
          to_email: values.email, // Replace with the recipient's email address
          from_name: values.name,
          message: message,
        },
        emailServiceConfig.userID
      );
      this.formRef.current.resetFields();


      console.log("Email sent successfully");
      message.success("Form submitted successfully!");

      // You can add any success handling here, e.g., show a success message
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle any errors that occur during the send process
    }
  };
  formRef = React.createRef();


  render() {
    return (
      <>
        <div className=" bg-white"
          id="ContactUs"
        >



          <section className=""
          >
            <h2 className="text-black text-center mt-5 mb-5">Contact Us</h2>

            <div className="container">
              <div className="">
                <div className="row">

                  <div className="col-lg-6 mb-30">
                    <div
                    // className="contact-info ovpr-dark"
                    // style={{ backgroundImage: "url(" + pic1 + ")" }}
                    >
                      <img src={pic1} />

                    </div>
                  </div>
                  <div className="col-lg-6 mb-30">
                    <Form
                      layout="vertical"
                      onFinish={this.handleFormSubmit} className="form-wraper contact-form ajax-form bg-gray p-3 rounded-4 "
                      ref={this.formRef}

                    >
                      <div className="ajax-message mb-3">Get in touch</div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item label="Your Name" name="Your Name" rules={[{ required: false, message: 'Please enter your Your Name' }]}>
                            <Input placeholder="Your Name" />
                          </Form.Item>

                        </div>
                        <div className="col-md-6">
                          <Form.Item label="Email Address" name="Email Address" rules={[{ required: false, message: 'Please enter your Email Address' }]}>
                            <Input placeholder="Email Address" />
                          </Form.Item>

                        </div>
                      </div>
                      <Form.Item label="Message" name="Message" rules={[{ required: false, message: 'Please enter your Message' }]}>
                        <Input.TextArea placeholder="Message" />
                      </Form.Item>
                      <Form.Item className="text-right d-flex justify-content-end ">
                        <Button htmlType="submit" className="btn  btn-primary btn-lg ">
                          Send Message
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section>



        </div>
      </>
    );
  }
}

export default ContactUs;
