import { Avatar, Collapse, Card } from "antd";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import bg1 from "../../images/main-banner/bg1.png";
import AddIcon from '@mui/icons-material/Add';
import doctorImg from "../../images/33029789_7994850copy1.png";

// Import Images
import testPic1 from "../../images/testimonials/pic1.jpg";
import testPic2 from "../../images/testimonials/pic2.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import testPic4 from "../../images/testimonials/pic4.jpg";
import testPic5 from "../../images/testimonials/pic5.jpg";
import testPic6 from "../../images/testimonials/pic6.jpg";
import WaitlistModal from "../../Modal/WaitlistModal";
const { Panel } = Collapse;



const TestimonialSection = () => {
  const [waitlistModalVisible, setWaitlistModalVisible] = useState(false);
  const showWaitlistModal = () => {
    setWaitlistModalVisible(true);
  };
  const hideWaitlistModal = () => {
    setWaitlistModalVisible(false);
  };

  const testimonials = [
    {
      id: 1,
      avatar: testPic1,
      name: "John Doe",
      position: "CEO, Company XYZ",
      review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      stars: 5,
    },
    // Add more testimonials similarly
  ];
  const handleJoinWaitlist = (email) => {
    // Handle the logic for joining the waitlist
    console.log('Joined waitlist with email:', email);
    hideWaitlistModal();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Change this breakpoint based on your design considerations
        settings: {
          slidesToShow: 2, // Show 2 slides on screens smaller than 768px (i.e., mobile)
        },
      },
    ],
  };
  const questions = [
    {
      question: 'What is Faith Connect?',
      answer:
        'Faith Connect is a way to connect Church leaders to new believers and their members. Faith Connect is a tool for evangelism, discipleship, outreach and getting members involved in the Life of their Church.',
    },
    {
      question: 'How do I get the Faith Connect app into my Church?',
      answer:
        `Church leaders sign up to Faith Connect via our website, and gain access to an online admin panel. Upon signing up you will gain access to a wide range of instructional video. We understand the struggle of having to learn new systems, so we've ensured that your church portal is simple as can be! Once you have signed up, get your members to download the app, and start connecting with them via all the different features!`,
    },
    {
      question: 'Can I use Faith Connect with any church?',
      answer:
        'Yes! Each church signed up to Faith connect has their own personal portal, which their community connects to. Once your community has connected with your portal, they exclusively see your content.',
    },
    {
      question: 'What if I don\'t want my prayer request to be public?',
      answer:
        'When submitting a prayer request on Faith Connect, users have the option to submit their prayer request to the public forum, or privately to the church leaders. Users also have the option to submit their prayer request anonymously!',
    },
    {
      question:
        'How can my members get discipled Church ministries (Youth, Men\'s Ministry, etc) using Faith Connect?',
      answer:
        'Leaders of the Church will be able to create the specific ministries in their Church with just a few clicks. Members can easily join ministries directly via the app. Members will then have access to gathering information and discipleship questions they can answer, submit and review directly in the app.',
    },
  ];

  return (
    <>
      {/* <section className="section-area section-sp3 overflow-hidden  testimonial-wraper bg-gray">
        <h2 className="titl text-center text-black e m-b0">
          What People Say
        </h2>

        <div className="row mt-5 pb-5 pl-100 " style={{}}>
          <Slider {...settings}>
            <div className="px-1">
              <div className="px-1">

                <div key={testimonials[0]?.id} className="testimonial-card bg-white  feature-bx2 ">
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <div className="testimonial-avatar">
                        <Avatar size={44} src={testimonials[0]?.avatar} alt="User Avatar" />
                      </div>
                      <div className="testimonial-info mx-2">
                        <p className="mb-0">{testimonials[0]?.name}</p>
                        <p className="text-black-50 " style={{ fontSize: 12 }}>{testimonials[0]?.position}</p>
                      </div>

                    </div>
                    <p
                      className="mb-0"
                      style={{ fontSize: 12 }}
                    >{testimonials[0]?.review}</p>
                    <div className="testimonial-stars">
                      {Array.from({ length: testimonials[0]?.stars }).map((_, index) => (
                        <span key={index} className="text-secondary ">&#9733;</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1">
              <div className="px-1">

                <div key={testimonials[0]?.id} className="testimonial-card bg-white  feature-bx2 ">
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <div className="testimonial-avatar">
                        <Avatar size={44} src={testimonials[0]?.avatar} alt="User Avatar" />
                      </div>
                      <div className="testimonial-info mx-2">
                        <p className="mb-0">{testimonials[0]?.name}</p>
                        <p className="text-black-50 " style={{ fontSize: 12 }}>{testimonials[0]?.position}</p>
                      </div>

                    </div>
                    <p
                      className="mb-0"
                      style={{ fontSize: 12 }}
                    >{testimonials[0]?.review}</p>
                    <div className="testimonial-stars">
                      {Array.from({ length: testimonials[0]?.stars }).map((_, index) => (
                        <span key={index} className="text-secondary ">&#9733;</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1">
              <div className="px-1">

                <div key={testimonials[0]?.id} className="testimonial-card bg-white  feature-bx2 ">
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <div className="testimonial-avatar">
                        <Avatar size={44} src={testimonials[0]?.avatar} alt="User Avatar" />
                      </div>
                      <div className="testimonial-info mx-2">
                        <p className="mb-0">{testimonials[0]?.name}</p>
                        <p className="text-black-50 " style={{ fontSize: 12 }}>{testimonials[0]?.position}</p>
                      </div>

                    </div>
                    <p
                      className="mb-0"
                      style={{ fontSize: 12 }}
                    >{testimonials[0]?.review}</p>
                    <div className="testimonial-stars">
                   
                      {Array.from({ length: testimonials[0]?.stars }).map((_, index) => (
                        <span key={index} className="text-secondary ">&#9733;</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1">
              <div className="px-1">

                <div key={testimonials[0]?.id} className="testimonial-card bg-white  feature-bx2 ">
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <div className="testimonial-avatar">
                        <Avatar size={44} src={testimonials[0]?.avatar} alt="User Avatar" />
                      </div>
                      <div className="testimonial-info mx-2">
                        <p className="mb-0">{testimonials[0]?.name}</p>
                        <p className="text-black-50 " style={{ fontSize: 12 }}>{testimonials[0]?.position}</p>
                      </div>

                    </div>
                    <p
                      className="mb-0"
                      style={{ fontSize: 12 }}
                    >{testimonials[0]?.review}</p>
                    <div className="testimonial-stars">
                      {Array.from({ length: testimonials[0]?.stars }).map((_, index) => (
                        <span key={index} className="text-secondary ">&#9733;</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1">
              <div className="px-1">

                <div key={testimonials[0]?.id} className="testimonial-card bg-white  feature-bx2 ">
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <div className="testimonial-avatar">
                        <Avatar size={44} src={testimonials[0]?.avatar} alt="User Avatar" />
                      </div>
                      <div className="testimonial-info mx-2">
                        <p className="mb-0">{testimonials[0]?.name}</p>
                        <p className="text-black-50 " style={{ fontSize: 12 }}>{testimonials[0]?.position}</p>
                      </div>

                    </div>
                    <p
                      className="mb-0"
                      style={{ fontSize: 12 }}
                    >{testimonials[0]?.review}</p>
                    <div className="testimonial-stars">
                      {Array.from({ length: testimonials[0]?.stars }).map((_, index) => (
                        <span key={index} className="text-secondary ">&#9733;</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>



        </div>
        <div
          className="main-"
          style={{ backgroundImage: "url(" + bg1 + ")" }}
        >
          <div className="container inner-content">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6 col-sm-7">
                <h1 className="">Join over 300 Churches in a New Form of Evangelism & Discipleship!

                </h1>
                <p className="fw-bold ">Ready to take your church experience to the next level?</p>
                <p className=" ">Get started with Faith Connect now and join thousands of others who are already enjoying the benefits of staying connected with their faith community. Our app will be available soon for download on both the App Store and Google Play Store. Simply click the button below to Join the word Waitlist</p>
                <div className="d-flex justify-content-between">


                  <Link
                    onClick={showWaitlistModal}

                    className="btn btn-primary  py-2 shadow "
               


                  >

                    <AddIcon />       Join the Waitlist

                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-5">
                <div className="banner-img">
                  <img src={doctorImg} alt="" />
                </div>
              </div>
            </div>
          </div>


        </div>
      </section> */}
      <div className="bg-gray"
        id="FAQs"
      >

        <div className="m-auto frequently-asked-questions">
          <h2 className="text-center text-black pt-5  pb-5">Frequently Asked Questions</h2>
          <div className="col-md-6 m-auto pb-5 px-3">

            {questions.map((faq, index) => (
              <Collapse expandIconPosition="right" accordion className="mb-3 bg-white">
                <Panel header={faq.question} key={index}>
                  <>
                    <p className="fw-normal ">{faq.answer}</p>
                  </>
                </Panel>
              </Collapse>
            ))}
          </div>
        </div>
      </div>
      <WaitlistModal
        visible={waitlistModalVisible}
        onCancel={hideWaitlistModal}
        onJoinWaitlist={handleJoinWaitlist}
      />
    </>
  );
}

export default TestimonialSection

