import React, { useEffect, useState } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";

// Elements
import FeatureSection3 from "../elements/feature-section3";
import TeamSection from "../elements/team";
import LatestNewsSection from "../elements/latest-news-slider";
// import { Icon } from '@mui/material';
// import '@material-icons/iconfont/material-icons.css';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import { Button } from "antd";
import { firebase } from "../../firebase/config";
import SVGIcons from "./SVGIcons";
// import firebase from "firebase"
function Services() {
  // const history=useHistory()
  const navigate = useNavigate();
  const [services, setServices] = useState([]);

  // Function to fetch services from Firebase and set them in state
  const getServices = async () => {
    try {
      const servicesRef = firebase.firestore().collection('services')
        .orderBy('number', 'asc')
      const snapshot = await servicesRef.get();
      const servicesData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log(servicesData)
      setServices(servicesData);
    } catch (error) {
      console.error('Error getting services: ', error);
    }
  };

  useEffect(() => {
    // Fetch services when the component mounts
    getServices();
  }, []);

  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Services</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    {/* <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li> */}
                    <li
                      className="breadcrumb-item text-white"
                      aria-current="page"
                    >
                      Practice Management Solutions
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>
        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              {services.map((el) => {
                return (
                  <div className="col-lg-4 col-md-6 mb-30">
                    <div className="feature-container feature-bx2 feature1">
                      <div className="feature-box-xl mb-20">
                        <span className="icon-cell">
                          {/* {el.image} */}
                          <span className="material-icons  color-primary"
                            style={{ color: '#004585', fontSize: 55, lineHeight: '8rem' }}
                          >
                            {el.image}
                          </span>
                          {/* <SVGIcons svgData={el.image} /> */}
                          {/* <Icon>f109</Icon> */}

                          {/* <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="54px" viewBox="0 0 24 24" width="54px" fill="#004585"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M20,6h-4V4c0-1.1-0.9-2-2-2h-4C8.9,2,8,2.9,8,4v2H4C2.9,6,2,6.9,2,8v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M10,4h4v2h-4V4z M16,15h-3v3h-2v-3H8v-2h3v-3h2v3h3V15z" /></g></g></svg> */}

                        </span>
                      </div>
                      <div className="icon-content">
                        <h3 className="ttr-title">{el.name}</h3>
                        <p>
                          {el.serviceIntro}                    </p>

                        <Link to={`/service-detail/Collection Solutions/${el.id}`} className="btn btn-primary light">
                          More
                        </Link>

                      </div>
                    </div>
                  </div>
                )
              })}

              {/* <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature3">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <g>
                          <rect fill="none" height="24" width="24" />
                          <path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M13.5,13 c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5c0-0.83,0.67-1.5,1.5-1.5S13.5,12.17,13.5,13z M12,9c-2.73,0-5.06,1.66-6,4 c0.94,2.34,3.27,4,6,4s5.06-1.66,6-4C17.06,10.66,14.73,9,12,9z M12,15.5c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5 c1.38,0,2.5,1.12,2.5,2.5C14.5,14.38,13.38,15.5,12,15.5z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">MD Audit Shield (RAC)</h3>
                    <p>
                      Why take a risk in your practice? Begin using our MD
                      Audit Shield services to eliminate the chances of an
                      audit proving inconsistencies in your billing.
                    </p>
                    <div

onClick={() => {
  navigate('/service-detail/MD Audit Shield (RAC)', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>

                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature2">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">EMR / EHR Software</h3>
                    <p>
                      Comprehensive certified EHR Solutions at your
                      fingertipes
                    </p>
                    <div

                      onClick={() => {
                        navigate('/service-detail/Medical & Dental Billing', {
                          state: {
                            image: 'https://www.doc-adv.com/EMR/Images/FromEHR-31.png',
                            // title:'We offer full practice management and billing solutions.',
                            titleData: `EMR-office is the complete EHR Solution for the medical office.Our EMR Software's design concentrates frequently accessed patient data in just a few screens: EMR Software Practice View screen provides an up-to-the-minute overview of all office activity. At-a-glance tools include:`,
                            checboxArray: [
                              'Calendar',
                              `FToDo's with visual status indicators`,
                              'Patient Tracker with color coding',
                              'Detailed message list with patient identifiers',
                              'PAppointment schedule',




                            ],
                            befiftPara: `Implementing a certified EMR software brings many benefits to your practice. EMR-office delivers on the promise of Electronic Health Records to increase productivity and improve patient care in small to mid-sized medical offices. It combines the right mix of rich functionality, intuitive ease of use, and affordability needed to enable rapid and complete documentation with a minimum of cost and disruption.`,
                            caarsoulTitle: `We can provide hundreds of reports. See the examples below
                         Select 4-6 images of reports from current website and post`
                            ,
                            carsoulDaata: [
                              {
                                title: `Improved Charting`,
                                paraGraaph: `EMR-office was designed and refined over many years by a practicing physician to provide superior charts with a minimal amount of time and effort. With features like Templates and PopUp Text, your documentation is complete, accurate and legible.`
                              },
                              {
                                title: `More Patients in Less Time
                            `,
                                paraGraaph: `Many of our doctors report that they can increase their patient load without extending their hours. And when they leave for the day, all of their charts are complete. No more evening and weekend charting.

                            `
                              },
                              {
                                title: `Improved Coding
                            `,
                                paraGraaph: `Coding correctly means improved profitability for the practice. The E&M Coder built into the EMR enables doctors to capture all of the information needed to code correctly. The EMR software will generate the proper codes from the description of the diagnosis and procedures determined by the physician. This information can be transferred directly to MedOffice, your billing service system!`
                              },
                              {
                                title: `No Lost or Misplaced Charts
                            `,
                                paraGraaph: `Medical offices run on patient charts. If one is lost, misplaced, or even stalled at one location in the office, an office visit can grind to a halt. With EMR-office software, patient charts are always available, and can be used by more than one staff member at a time.`
                              },
                              {
                                title: `Dramatically Improved Workflow`,
                                paraGraaph: `With EMR, patient information is easier to access, update, use and share. From "always available" patient charts to integrated tools like Messaging, ToDo Lists, and shared scheduling, EMR-office makes the medical office run more efficiently.`
                              },
                              {
                                title: `Legible Prescriptions
                            `,
                                paraGraaph: `The EMR software's prescription handling capabilities print or fax accurate prescriptions every time - in a form that is complete and readable, and complies with state-specific requirements. Patients or their pharmacist will never again have to call you back to clarify an unreadable or incomplete prescription.
                            `
                              },
                              {
                                title: `Fully Informed Specialists
                            `,
                                paraGraaph: `Successful medical referrals depend on complete information exchanged between physicians. EMR-office makes it fast and efficient to pass relevant medical data to specialists to ensure better care and more satisfied patients.`
                              },
                              {
                                title: `Higher Confidence / Faster Visits
                            `,
                                paraGraaph: `With EMR-office, patients know that doctors have complete medical information and history including test results, prescriptions, and previous diagnoses instantly available at the point of care. And many visits are faster due to higher levels of efficiency.`
                              }

                            ]
                            //  carsoulArray:[
                            //   ``
                            //  ]

                          }
                        });
                      }}

                      className="btn btn-primary light">
                      View More
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature3">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0,0h24v24H0V0z" fill="none" />
                        <g>
                          <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" />
                          <rect height="2" width="6" x="9" y="7" />
                          <rect height="2" width="2" x="16" y="7" />
                          <rect height="2" width="6" x="9" y="10" />
                          <rect height="2" width="2" x="16" y="10" />
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Collection Solutions</h3>
                    <p>
                      We wish to provide you the ability to close the book on
                      every insurance claim.
                    </p>
                  
                      <div

onClick={() => {
  navigate('/service-detail/Collection Solutions', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
     

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature2">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">
                      Electronic Fund Transfer (EFT)
                    </h3>
                    <p>
                      EFT allows for electronic transfer of funds from your
                      patient's bank account directly into your account!
                    </p>
                    
                           <div

onClick={() => {
  navigate('/service-detail/Electronic Fund Transfer (EFT', );
}}

className="btn btn-primary light">
View More
</div>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature3">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <g>
                          <rect fill="none" height="24" width="24" />
                          <path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M13.5,13 c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5c0-0.83,0.67-1.5,1.5-1.5S13.5,12.17,13.5,13z M12,9c-2.73,0-5.06,1.66-6,4 c0.94,2.34,3.27,4,6,4s5.06-1.66,6-4C17.06,10.66,14.73,9,12,9z M12,15.5c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5 c1.38,0,2.5,1.12,2.5,2.5C14.5,14.38,13.38,15.5,12,15.5z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">MD Audit Shield (RAC)</h3>
                    <p>
                      Why take a risk in your practice? Begin using our MD
                      Audit Shield services to eliminate the chances of an
                      audit proving inconsistencies in your billing.
                    </p>
                    <div

onClick={() => {
  navigate('/service-detail/MD Audit Shield (RAC)', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <g>
                          <rect fill="none" height="24" width="24" />
                        </g>
                        <g>
                          <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Physician Credentialing</h3>
                    <p>
                      Are you frustrated with credentialing or just too busy
                      to do it yourself? Let us help you get this important
                      process done right.
                    </p>
                    
                                        <div

onClick={() => {
  navigate('/service-detail/Physician Credentialing', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">CodeMAXX Services</h3>
                    <p>
                      {" "}
                      A service that provides busy medical practices with
                      access to certified professional coders who will code
                      your encounter notes.{" "}
                    </p>
                    
                                     <div

onClick={() => {
  navigate('/service-detail/CodeMAXX Services', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Well Care Services</h3>
                    <p>
                      {" "}
                      As a well-care service provider, we manage your outreach
                      program to help maintain communication with your
                      patients.{" "}
                    </p>
                    
                               <div

onClick={() => {
  navigate('/service-detail/Well-Care Services', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Patient Assessment</h3>
                    <p>
                      While you examine your patient's physical status, why
                      not learn more about their mental status in just minutes
                      and get paid for it?{" "}
                    </p>
                    
                         <div

onClick={() => {
  navigate('/service-detail/Patient Assessment ', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <circle cx="9" cy="9" r="4" />
                        <path d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Transcription Services</h3>
                    <p>
                      {" "}
                      Our transcription process is secure and provides a quick
                      & high-quality service while minimizing costs.
                    <div

onClick={() => {
  navigate('/service-detail/Transcription Services', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <rect fill="none" height="24" width="24" />
                        <path d="M7,3H4v3H2V1h5V3z M22,6V1h-5v2h3v3H22z M7,21H4v-3H2v5h5V21z M20,18v3h-3v2h5v-5H20z M19,18c0,1.1-0.9,2-2,2H7 c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M15,8H9v2h6V8z M15,11H9v2h6V11z M15,14H9v2h6V14z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Document Management</h3>
                    <p>
                      {" "}
                      We scan documents and store them on digital media for
                      clients to access and retrieve easily any time.{" "}
                    </p>
                  
                          <div

onClick={() => {
  navigate('/service-detail/Document Management', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,
      //  carsoulArray:[
      //   ``
      //  ]

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="80px"
                        viewBox="0 0 24 24"
                        width="80px"
                        fill="#0E4777"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z" />
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Remote Back-up Services </h3>
                    <p>
                      {" "}
                      With businesses depending more and more on the data
                      stored in their computers, proper backups are becoming
                      much more critical.{" "}
                    </p>
                    <div

onClick={() => {
  navigate('/service-detail/Back-up Services ', {
    state: {
      image: 'https://www.doc-adv.com/Images/Banners/BillingSolutions.gif',
      title: '',
      titleData: `We offer full practice management and billing solutions. We begin each relationship with understanding our customer's needs. Open communication and transparency are at every stage of the partnership. We listen to our customers and take a proactive role to improve each client's business. We understand how important your work is and know we can play a significant role in helping you meet your day-to-day challenges.Our services include:`,
      checboxArray: [
        'Process primary, secondary, tertiary insurance claims.',
        'Follow up with insurance (primary/secondary).',
        'Post payments to insurance and patient accounts.',
        'Track unpaid claims and send Letters of Medical Necessity when necessary.',
        'Provide notification regarding any "Incomplete Claims" which were unable to be processed as a result, or when additional information is required by the insurance carrier to adjudicate claims.',
        `Follow up on patients' payments and generate patient statements.`,
        `Receive monthly and annual financial analysis and review of medical codes.`,
        `Process Workers Compensation, Rehab, DME Claims with attachments.`,
        `Provide you with comprehensive reports: We can provide you with highly detailed reports with colorful analysis charts and graphics tracking the status of every claim and showing the level of productivity of your practice.`,


      ],
      caarsoulTitle: `We can provide hundreds of reports. See the examples below
   Select 4-6 images of reports from current website and post`
      ,

    }
  });
}}

className="btn btn-primary light">
View More
</div>
                 
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <FeatureSection3 />

        {/* <TeamSection /> */}

        {/* <LatestNewsSection /> */}
      </div >
    </>
  );
}

export default Services;
