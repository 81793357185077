import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

// Import Images
import aboutThumb1 from "../../images/about/pic-1.jpg";
import aboutThumb2 from "../../images/78175172_M011T0649AIphone15ProMax21Sep23copy.png";
import aboutThumb3 from "../../images/about/pic-3.jpg";
import bg1 from "../../images/akira-hojo-_86u_Y0oAaM-unsplash.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/VideoPlaceholder.png";
import WaitlistModal from "../../Modal/WaitlistModal";
import { Button } from "antd";



const AboutSection = () => {
  const [waitlistModalVisible, setWaitlistModalVisible] = useState(false);
  const showWaitlistModal = () => {
    setWaitlistModalVisible(true);
  };
  const hideWaitlistModal = () => {
    setWaitlistModalVisible(false);
  };
  const handleJoinWaitlist = (email) => {
    // Handle the logic for joining the waitlist
    console.log('Joined waitlist with email:', email);
    hideWaitlistModal();
  };
  return (
    <>
      <section className=" about-area">
        <div className="container">

          <div className="row"

          >
            <h2 className="text-center mb-4 text-black">Experience Faith Connect</h2>
            <div className="col-md-8 m-auto d-flex justify-content-center ">


              <iframe className="iframeSize" src="https://www.youtube.com/embed/TZApObtUuHo?si=6casKCluhWQlK_aU&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
            </div>
            {/* <img className="mt-3 p-5" src={ptImg5} alt="" /> */}



            <span className="span text-center mt-5">

              <Link onClick={showWaitlistModal}
                className="btn btn-outline-primary btn-sm  ">

                Join the Waitlist <AddIcon />
              </Link>
            </span>
          </div>
        </div>


      </section>

      <section className="section-area  "
        id="Features"
      >
        <h2 className="text-center mb-5 mt-5 text-black">Why Download Faith Connect?</h2>
        <div
          // className="main-banner"
          style={{ backgroundImage: "url(" + bg1 + ")" }}

        >

          <div className="container">
            <div className="row g-3 justify-content-center ">
              <div className="col-md-4">
                <div className="slider-item h-100">
                  <div className="feature-container pb-0 h-100 m-0 feature-bx2 feature1">
                    <div className="feature-box-xl ">
                      <span className="icon-cell">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="60" viewBox="0 0 50 60" fill="none">
                          <path d="M7.50098 45H50.001V47.5C50.001 54.4025 44.4035 60 37.501 60H7.50098C3.35848 60 0.000976562 56.6425 0.000976562 52.5C0.000976562 48.3575 3.35848 45 7.50098 45ZM50.001 12.5V40H7.50098C6.99348 40 6.48598 40.05 5.98848 40.1525C3.81348 40.4125 1.74848 41.245 0.000976562 42.5625V12.4325C0.000976562 5.5725 5.56098 0 12.4185 0H37.501C44.4035 0 50.001 5.5975 50.001 12.5ZM35.001 17.5C35.001 16.12 33.881 15 32.501 15H27.501V10C27.501 8.62 26.381 7.5 25.001 7.5C23.621 7.5 22.501 8.62 22.501 10V15H17.501C16.121 15 15.001 16.12 15.001 17.5C15.001 18.88 16.121 20 17.501 20H22.501V30C22.501 31.38 23.621 32.5 25.001 32.5C26.381 32.5 27.501 31.38 27.501 30V20H32.501C33.881 20 35.001 18.88 35.001 17.5Z" fill="#D89D29" />
                        </svg>

                      </span>
                    </div>
                    <div className="icon-content">
                      <h6 className="ttr-title text-black">Disciple Me</h6>
                      <p>
                        Participate in guided discussions and questions to grow in your faith journey.
                      </p>
                      <p style={{ fontStyle: 'italic' }}>
                        "Whoever wants to be my disciple must deny themselves and take up their cross daily and follow me." Luke 9:23
                      </p>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="slider-item h-100">
                  <div className="feature-container pb-0 h-100 m-0 feature-bx2 feature1">
                    <div className="feature-box-xl ">
                      <span className="icon-cell">


                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                          <path d="M24.75 27.2409V39.6271C24.75 42.0076 24.381 44.3341 23.679 46.5391C22.2368 51.0684 17.8358 54.0001 13.0838 54.0001H4.5C2.01375 54.0001 0 51.9864 0 49.5001C0 48.3144 0.38025 47.1736 1.0215 46.1769C1.818 44.9439 2.25 43.4994 2.25 42.0099V27.2814C2.25 23.4429 3.47625 19.7056 5.751 16.6141L16.5623 1.92164C17.9865 -0.114605 20.7922 -0.611855 22.8285 0.812395C24.8647 2.23664 25.362 5.04239 23.9377 7.07864L14.4225 19.9036C14.2357 20.1489 11.25 22.5001 11.25 27.0001V33.7501C11.25 34.9921 12.258 36.0001 13.5 36.0001C14.742 36.0001 15.75 34.9921 15.75 33.7501V27.0001C15.75 24.2664 18.1868 22.1041 21.006 22.5609C23.2178 22.9209 24.75 24.9976 24.75 27.2409ZM51.75 42.0121V27.2836C51.75 23.4451 50.5238 19.7079 48.249 16.6164L37.4377 1.92164C36.0135 -0.114605 33.2078 -0.611855 31.1715 0.812395C29.1353 2.23664 28.638 5.04239 30.0623 7.07864L39.5775 19.9036C39.7643 20.1489 42.75 22.5001 42.75 27.0001V33.7501C42.75 34.9921 41.742 36.0001 40.5 36.0001C39.258 36.0001 38.25 34.9921 38.25 33.7501V27.0001C38.25 24.2664 35.8132 22.1041 32.994 22.5609C30.7822 22.9209 29.25 24.9976 29.25 27.2409V39.6271C29.25 42.0076 29.619 44.3341 30.321 46.5391C31.7632 51.0684 36.1642 54.0001 40.9162 54.0001H49.5C51.9863 54.0001 54 51.9864 54 49.5001C54 48.3144 53.6197 47.1736 52.9785 46.1769C52.182 44.9439 51.75 43.4994 51.75 42.0099V42.0121Z" fill="#D89D29" />
                        </svg>
                      </span>
                    </div>
                    <div className="icon-content">
                      <h6 className="ttr-title text-black">Prayer Requests</h6>
                      <p>
                        Submit prayer requests and pray for your Church community

                      </p>
                      <p style={{ fontStyle: 'italic' }}>
                        "Whatever you ask in prayer, believe that you have received it, and it will be yours." Mark 11:24
                      </p>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="slider-item h-100">
                  <div className="feature-container pb-0 h-100 m-0 feature-bx2 feature1">
                    <div className="feature-box-xl ">
                      <span className="icon-cell">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                          <path d="M32.5 47.5V60H27.5V47.5C27.5 46.1225 28.6225 45 30 45C31.3775 45 32.5 46.1225 32.5 47.5ZM25 10H27.5V14.9775L17.5 22.25V60H22.5V47.5C22.5 43.365 25.865 40 30 40C34.135 40 37.5 43.365 37.5 47.5V60H42.5V22.25L32.5 14.9775V10H35C36.38 10 37.5 8.88 37.5 7.5C37.5 6.12 36.38 5 35 5H32.5V2.5C32.5 1.12 31.38 0 30 0C28.62 0 27.5 1.12 27.5 2.5V5H25C23.62 5 22.5 6.12 22.5 7.5C22.5 8.88 23.62 10 25 10ZM12.5 25.885L5.1475 31.2325C1.9125 33.585 0 37.3425 0 41.3425V51.25C0 56.0825 3.9175 60 8.75 60H12.5V25.885ZM54.8525 31.2325L47.5 25.885V60H51.25C56.0825 60 60 56.0825 60 51.25V41.3425C60 37.3425 58.085 33.585 54.8525 31.2325Z" fill="#D89D29" />
                        </svg>
                      </span>
                    </div>
                    <div className="icon-content">
                      <h6 className="ttr-title text-black">Ministry Leader</h6>
                      <p>
                        Sign up for different ministries and get involved in the Life of your Church
                        in serving your church.
                      </p>
                      <p style={{ fontStyle: 'italic' }}>
                        "Go into all the world and preach the Good News to everyone." Mark 16:15
                      </p>



                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="slider-item h-100">
                  <div className="feature-container pb-0 h-100 m-0 feature-bx2 feature1">
                    <div className="feature-box-xl ">
                      <span className="icon-cell">

                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                          <g clip-path="url(#clip0_4874_18258)">
                            <path d="M54 36C54 37.65 55.35 39 57 39H63C64.65 39 66 37.65 66 36C66 34.35 64.65 33 63 33H57C55.35 33 54 34.35 54 36Z" fill="#D89D29" />
                            <path d="M49.7687 50.4597C48.7787 51.7797 49.0487 53.6097 50.3687 54.5697C51.9587 55.7397 53.6387 56.9997 55.2287 58.1997C56.5487 59.1897 58.4087 58.9197 59.3687 57.5997C59.3687 57.5697 59.3987 57.5697 59.3987 57.5397C60.3887 56.2197 60.1187 54.3597 58.7987 53.3997C57.2087 52.1997 55.5287 50.9397 53.9687 49.7697C52.6487 48.7797 50.7887 49.0797 49.7987 50.3997C49.7987 50.4297 49.7687 50.4597 49.7687 50.4597Z" fill="#D89D29" />
                            <path d="M59.4295 14.4301C59.4295 14.4001 59.3995 14.4001 59.3995 14.3701C58.4095 13.0501 56.5495 12.7801 55.2595 13.7701C53.6695 14.9701 51.9595 16.2301 50.3995 17.4301C49.0795 18.4201 48.8395 20.2801 49.8295 21.5701C49.8295 21.6001 49.8595 21.6001 49.8595 21.6301C50.8495 22.9501 52.6795 23.2201 53.9995 22.2301C55.5895 21.0601 57.2695 19.7701 58.8595 18.5701C60.1495 17.6101 60.3895 15.7501 59.4295 14.4301Z" fill="#D89D29" />
                            <path d="M24 27H12C8.7 27 6 29.7 6 33V39C6 42.3 8.7 45 12 45H15V54C15 55.65 16.35 57 18 57C19.65 57 21 55.65 21 54V45H24L39 54V18L24 27Z" fill="#D89D29" />
                            <path d="M46.5 35.9997C46.5 32.0097 44.76 28.4097 42 25.9497V46.0197C44.76 43.5897 46.5 39.9897 46.5 35.9997Z" fill="#D89D29" />
                          </g>
                          <defs>
                            <clipPath id="clip0_4874_18258">
                              <rect width="72" height="72" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                    <div className="icon-content">
                      <h6 className="ttr-title text-black">Stay Updated</h6>
                      <p>
                        Receive announcements, event notifications, and important updates from your church.
                      </p>

                      <p style={{ fontStyle: 'italic' }}>
                        "Turn away from evil and do good; seek peace and pursue it." Psalm 34:14

                      </p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="slider-item h-100">
                  <div className="feature-container pb-0 h-100 m-0 feature-bx2 feature1">
                    <div className="feature-box-xl ">
                      <span className="icon-cell">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                          <path d="M54 0H6C2.7 0 0.03 2.7 0.03 6L0 60L12 48H54C57.3 48 60 45.3 60 42V6C60 2.7 57.3 0 54 0ZM15 21H45C46.65 21 48 22.35 48 24C48 25.65 46.65 27 45 27H15C13.35 27 12 25.65 12 24C12 22.35 13.35 21 15 21ZM33 36H15C13.35 36 12 34.65 12 33C12 31.35 13.35 30 15 30H33C34.65 30 36 31.35 36 33C36 34.65 34.65 36 33 36ZM45 18H15C13.35 18 12 16.65 12 15C12 13.35 13.35 12 15 12H45C46.65 12 48 13.35 48 15C48 16.65 46.65 18 45 18Z" fill="#D89D29" />
                        </svg>
                      </span>
                    </div>
                    <div className="icon-content">
                      <h6 className="ttr-title text-black">Community Chat</h6>
                      <p>
                        Connect with other members of your church and share experiences.
                      </p>

                      <p style={{ fontStyle: 'italic' }}>
                        "A new command I give you: Love one another. As I have loved you, so you must love one another." John 13:34
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="heading-bx text-center">
              <h2 className="text-black mt-5">How It Works: Church Leaders
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">1</div>
                  <div className="work-content">
                    <h5 className="title text-black fw-semibold  mb-10">
                      Sign Up

                    </h5>
                    <p>
                      Join the waitlist and receive confirmation when you are able to sign up to the web app!
                    </p>
                  </div>


                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">2</div>
                  <div className="work-content">
                    <h5 className="title text-black fw-semibold  mb-10">
                      Build Your Profile

                    </h5>
                    <p>
                      Add your Church name, telephone number, address, picture, service times and even social media in your dashboard! Members will be able to view this in the "My Profile" section of their app

                    </p>
                  </div>


                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">3</div>
                  <div className="work-content">
                    <h5 className="title text-black fw-semibold  mb-10">
                      Encourage Members to Download
                    </h5>
                    <p>
                      Create a unique code in your profile that members can use to login to your churches profile on their app. Members can also choose your Church from the drop down list on their login page
                    </p>
                  </div>


                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">4</div>
                  <div className="work-content">
                    <h5 className="title text-black fw-semibold  mb-10">
                      Create Ministries
                    </h5>
                    <p>
                      With a few clicks of a button, you'll be able to create the different ministries within your Church! In your dashboard you'll be able to see all the members involved in different ministries and more!

                    </p>
                  </div>


                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">5</div>
                  <div className="work-content">
                    <h5 className="title text-black fw-semibold  mb-10">
                      Track Your Numbers!
                    </h5>
                    <p>
                      Members are able to submit applications in the app to become a member of your Church and even to be Baptized! In your dashboard you'll be able to see the stats on all those who have connected to your Church, are Church members, those participating in ministries and those who would like to be baptized!
                    </p>
                  </div>


                </div>
              </div>


            </div>
            <img src={aboutThumb2} className="p-5" />
          </div>
        </div>
        <p className="span text-center mt-5">

          <Link className="btn btn-outline-primary btn-sm  "
            // onClick={showWaitlistModal}
            onClick={showWaitlistModal}

          >

            Join the Waitlist <AddIcon />
          </Link>
        </p>
        {/* <img className="pt-img1 animate-rotate" src={lineCircleBlue} alt="" />
        <img className="pt-img2 animate2" src={squareDotsOrange} alt="" />
        <img className="pt-img3 animate-wave" src={waveBlue} alt="" />
        <img className="pt-img4 animate1" src={squareRotate} alt="" /> */}
      </section>
      <section
        id="HowWorks"
        className="section-area section-sp5 work-area"

      >
        <div className="container-sm" >
          <div className="heading-bx text-center">
            <h2 className="text-black mt-5">How It Works: Church Community
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="work-bx">
                <div className="work-num-bx">1</div>
                <div className="work-content">
                  <h5 className="title text-black fw-semibold  mb-10">
                    Download App
                  </h5>
                  <p>
                    Download the Faith Connect app from the App Store or Google Play Store.
                  </p>
                </div>


              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="work-bx">
                <div className="work-num-bx">2</div>
                <div className="work-content">
                  <h5 className="title text-black fw-semibold  mb-10">
                    Connect to your Church
                  </h5>
                  <p>
                    Sign up and connect to your church using the drop down list or a unique church code provided to you.

                  </p>
                </div>


              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="work-bx">
                <div className="work-num-bx">3</div>
                <div className="work-content">
                  <h5 className="title text-black fw-semibold  mb-10">
                    Onboard & Interact
                  </h5>
                  <p>
                    Explore features such as announcements, prayer requests, and more.
                  </p>
                </div>


              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="work-bx">
                <div className="work-num-bx">4</div>
                <div className="work-content">
                  <h5 className="title text-black fw-semibold  mb-10">
                    Get Discipled
                  </h5>
                  <p>
                    Grow in your faith journey with guided discussions and community support.
                  </p>
                </div>


              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="work-bx">
                <div className="work-num-bx">5</div>
                <div className="work-content">
                  <h5 className="title text-black fw-semibold  mb-10">
                    Get Involved
                  </h5>
                  <p>
                    Get involved in ministries, submit prayer requests, and engage with your church community.
                  </p>
                </div>


              </div>
            </div>


          </div>
        </div>

      </section>
      <WaitlistModal
        visible={waitlistModalVisible}
        onCancel={hideWaitlistModal}
        onJoinWaitlist={handleJoinWaitlist}
      />
    </>
  );
}

export default AboutSection

