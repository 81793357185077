import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import WaitlistModal from "../../Modal/WaitlistModal";
import bg1 from "../../images/main-banner/bg1.png";
import doctorImg from "../../images/doctorImages1.png";

const AboutSection = () => {
  const [waitlistModalVisible, setWaitlistModalVisible] = useState(false);
  const showWaitlistModal = () => {
    setWaitlistModalVisible(true);
  };
  const hideWaitlistModal = () => {
    setWaitlistModalVisible(false);
  };

  const handleJoinWaitlist = (email) => {
    // Handle the logic for joining the waitlist
    console.log('Joined waitlist with email:', email);
    hideWaitlistModal();
  };

  return (
    <>
      <div className="main-banner" style={{ backgroundImage: `url(${bg1})` }}>
        <div className="container inner-content">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 col-sm-7">
              <h1>Connecting Faith
                <br />
                Communities Like
                <br />
                Never Before </h1>
              <p className="fw-bold">Empowering Evangelism and Discipleship with Faith Connect
              </p>
              <p>Join over 300 Churches in connecting new believers to your Church community and reignite a fire within your existing Church members! <br />
                <br />
                <p className="fw-bold ">
                  <>
                    Matthew 28:19-20
                  </>
                </p>
                <span className="fw-bold">19</span> Therefore, go and make disciples of all the nations,baptizing them in the name of the Father and the Son and the Holy Spirit. <span className="fw-bold">20</span> Teach these new disciples to obey all the commands I have given you. And be sure of this: I am with you always, even to the end of the age.
              </p>
              <div className="d-flex justify-content-between">
                <Link
                  className="btn btn-primary py-2 shadow"
                  onClick={showWaitlistModal}
                >
                  <AddIcon /> Join the Waitlist
                </Link>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-5">
              <div className="banner-img">
                <img src={doctorImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WaitlistModal
        visible={waitlistModalVisible}
        onCancel={hideWaitlistModal}
        onJoinWaitlist={handleJoinWaitlist}
      />
    </>
  );
};

export default AboutSection;
