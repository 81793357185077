import React, { useState } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
// import { Email, MailOutlined } from '@material-ui/icons';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import firebase from "firebase";
// import { Phone } from '@material-ui/icons';
import Phone from '@mui/icons-material/PhoneEnabled';
// import { Phone } from '@material-ui/icons';
const WaitlistModal = ({ visible, onCancel, onJoinWaitlist }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);



  const onFinish = async (values) => {

    try {
      setLoading(true);
      const db = firebase.firestore();

      // Check if the email already exists in the waiting list
      const snapshot = await db.collection('waitingList').where('email', '==', values.email).get();
      if (!snapshot.empty) {
        // If email already exists, show a message and return
        notification.warning({
          message: 'Warning',
          description: 'Your email is already added to the waiting list.',
        });
        return;
      }


      // Add the email to the waiting list along with the collection UID
      const docRef = await db.collection('waitingList').add(values);

      // Get the document ID from the DocRef
      const docId = docRef.id;

      // Update the relevant collection with the document ID
      await db.collection('waitingList').doc(docId).update({ id: docRef.id });

      form.resetFields();
      onCancel();
      notification.success({
        message: 'Success',
        description: 'Your email has been added to the waiting list successfully.',
      });
    } catch (error) {
      console.error('Error adding email to waiting list:', error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while adding your email to the waiting list.',
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal
      title={<h4 className='text-center text-black '>Join the Waitlist</h4>}
      visible={visible}
      onCancel={onCancel}
      centered
      footer={null}
    >
      <p className=" fw-normal mb-2 ">
        Be the first to know when Faith Connect is available for download!
        <br />
        Enter your email address below to join our waiting list and join over 300 Churches in receiving exclusive updates, early access, and more!

      </p>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Form.Item
          className='mb-2'
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please enter your email!',
              type: 'email',
            },
          ]}
        >
          <Input prefix={<EmailIcon className='pr-4' />} className=' p-2  rounded-2 ' placeholder="Your Email" />
        </Form.Item>
        <Form.Item
          className='mb-0 '
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: 'Please enter your Phone Number!',
              // type: 'number',
            },
          ]}
        >
          <Input prefix={<Phone className='pr-4' />} className=' p-2  rounded-2 ' placeholder="Your Phone Number" />
        </Form.Item>
        <p className=" text-muted fw-normal   " style={{ fontSize: 13 }}>
          By joining the waitlist, you agree to receive updates and marketing emails from Faith Connect.
          You can unsubscribe at any time.
        </p>
        <div className="row px-2 justify-content-center ">
          {/* <div className="col-md-4 px-1">
            <Button style={{ height: 43, borderRadius: 12 }} type='' className='bg-gray w-100 text-danger fw-medium ' key="notNow" onClick={onCancel}>
              Not Now
            </Button>

          </div> */}
          <div className="col-md-8 px-1">
            <Button style={{ height: 43, borderRadius: 12 }} key="joinWaitlist" className='bg-primary text-white w-100' htmlType="submit" loading={loading}>
              Join the Waitlist
            </Button>

          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default WaitlistModal;