import React, { Component } from "react";
import { Link } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
// Images
import PhoneIcon from '@mui/icons-material/Phone';
import Logo from "../../images/LogoWhite.png";
import footerBg from "../../images/background/footer.jpg";
import ptImg1 from "../../images/shap/wave-blue.png";
import ptImg2 from "../../images/shap/circle-dots.png";
import ptImg3 from "../../images/shap/plus-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";

// Social Images
import facebook from "../../images/social/facebook.png";
import twitter from "../../images/social/twitter.png";
import instagram from "../../images/social/instagram.png";
import linkedin from "../../images/social/linkedin.png";

class aboutSection extends Component {
	render() {
		return (
			<>
				<footer
					className="footer"
				// style={{ backgroundImage: "url(" + footerBg + ")" }}
				>
					<div className="footer-top">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-xl-4 col-lg-4 col-md-6 text-center">
									<div className="widget widget_info">
										<div className="footer-logo">
											<Link to="/"><img className="w-100" src={Logo} alt="" /></Link>
										</div>
										<div className="ft-contact text-white">
											<p>Copyright © 2024 Faith Connect. All rights reserved.</p>


										</div>
									</div>
								</div>

								<div className="col-xl-4 col-lg-4 col-md-6 text-center">
									{/* <div className="footer-social-link">
										<ul>
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<g clip-path="url(#clip0_4874_18394)">
														<path d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													</g>
													<defs>
														<clipPath id="clip0_4874_18394">
															<rect width="24" height="24" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</a></li>
											<li>

												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M6 9H2V21H6V9Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</li>

											<li><a target="_blank"
												rel="noreferrer" href="https://twitter.com/"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
													<path d="M10.7142 7.62265L17.4162 0H15.8286L10.0068 6.61729L5.3604 0H0L7.0278 10.0075L0 18H1.5876L7.7316 11.0104L12.6396 18H18M2.1606 1.17142H4.5996L15.8274 16.8861H13.3878" fill="white" />
												</svg></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M15.9997 11.3698C16.1231 12.2021 15.981 13.052 15.5935 13.7988C15.206 14.5456 14.5929 15.1512 13.8413 15.5295C13.0898 15.9077 12.2382 16.0394 11.4075 15.9057C10.5768 15.7721 9.80947 15.3799 9.21455 14.785C8.61962 14.1901 8.22744 13.4227 8.09377 12.592C7.96011 11.7614 8.09177 10.9097 8.47003 10.1582C8.84829 9.40667 9.45389 8.79355 10.2007 8.40605C10.9475 8.01856 11.7975 7.8764 12.6297 7.99981C13.4786 8.1257 14.2646 8.52128 14.8714 9.12812C15.4782 9.73496 15.8738 10.5209 15.9997 11.3698Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M17.5 6.5H17.51" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</a></li>



										</ul>
									</div> */}
								</div>




								<div className="col-xl-3 col-lg-3 col-md-6">

									<p className="text-white"><EmailIcon />info@faithconnect.world</p>
									{/* <p className="text-white"><PhoneIcon />1-800-FAITH-APP</p> */}

								</div>
							</div>
						</div>
					</div>
					{/* <div className="container">
            <div className="footer-bottom">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="copyright-text">
                    Copyright © 2023 Design & Developed by{" "}
                    <a
                      href="https://themeforest.net/user/themetrades"
                      rel="noreferrer"
                      target="_blank"
                      className="text-secondary"
                    >
                      Doctor's Advantage
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
					{/* <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
          <img className="pt-img2 animate1" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" /> */}
				</footer>
			</>
		);
	}
}

export default aboutSection;
